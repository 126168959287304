import type {
  IFullCase,
  ISelectOption,
  IUserDataWithRoles,
  IUserSelectOption
} from '../../mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from '../fields/types';
import { parseUserToSelectOption } from '../../mobxStore/MetaDataStore';

const getDefaultSurgeon = (
  openedCase: IFullCase,
  surgeonsOptions: IUserSelectOption[]
): IUserSelectOption | undefined => {
  const isAttendingNotActive = openedCase.basicCase.data.attending?.isActive === false;
  if (!isAttendingNotActive) {
    return openedCase.basicCase.data.attendingId !== ''
      ? surgeonsOptions.find(option => option.value === openedCase.basicCase.data.attendingId)
      : undefined;
  }
  const attending = openedCase.basicCase.data.attending as IUserDataWithRoles;
  return parseUserToSelectOption(attending);
};

export const getDefaultValues = (
  openedCase: IFullCase,
  surgeonsOptions: IUserSelectOption[],
  careTeamOptions: IUserSelectOption[],
  servicesOptions: ISelectOption[],
  proceduresOptions: ISelectOption[]
): IFormData => {
  const defaultSurgeon = getDefaultSurgeon(openedCase, surgeonsOptions);

  const currentProcedure =
    openedCase.basicCase.data.procedureId !== ''
      ? proceduresOptions.find(p => p.value === openedCase.basicCase.data.procedureId)
      : undefined;
  const defaultService = servicesOptions.find(s => s.value === openedCase.basicCase.data.siteId);

  const defaultFollowers = careTeamOptions.filter(option =>
    openedCase.basicCase.data.caseFollowers.some(follower => follower.userId === option.value)
  );

  const defaultValues = {
    [FORM_FIELDS_ENUM.TITLE]: openedCase.basicCase.data.displayId ?? '',
    [FORM_FIELDS_ENUM.DESCRIPTION]: openedCase.basicCase.data.description ?? '',
    [FORM_FIELDS_ENUM.SURGEON]: defaultSurgeon,
    [FORM_FIELDS_ENUM.DATE]: openedCase.basicCase.data.caseDate,
    [FORM_FIELDS_ENUM.FOLLOWERS]: defaultFollowers,
    [FORM_FIELDS_ENUM.SERVICE]: defaultService,
    [FORM_FIELDS_ENUM.PROCEDURE]: currentProcedure,
    [FORM_FIELDS_ENUM.ROOM]: openedCase.basicCase.data.room ?? ''
  };
  return defaultValues;
};
