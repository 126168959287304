import { useForm } from 'react-hook-form';
import React, { type MouseEvent } from 'react';
import { useRootStore } from 'app/mobxStore';
import SurgeonSelect from 'app/components/fields/SurgeonSelect';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ServiceSelect from 'app/components/fields/ServiceSelect';
import CaseDate from 'app/components/fields/CaseDate';
import SubmitButton from 'app/components/buttons/SubmitButton';
import CloseButton from 'app/components/buttons/CloseButton';
import { isCurrentUserToHandleCase } from 'app/mobxStore/caseStoreHelper';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import './NewCaseForm.scss';
import { log } from 'debug';
import MomentAdapter from '@date-io/moment';
import CaseTitle from '../fields/CaseTitle';
import { TabsEnum } from '../../mobxStore/types';
import { toast } from 'react-toastify';
import useOurSearchParams from '../caseViews/hooks/useOurSearchParams';
import { getWillNotifyNewCase } from 'app/utils/willNotifyHelper';
import ClockIcon from 'app/assets/icons/Clock_icon';
import CommentIcon from 'app/assets/icons/Comment_icon';
import variables from 'app/utils/variables.module.scss';
import { lite } from '../../services/apiService/apiService';
import AmplService from '../../services/amplService';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

const NewCaseForm = (): React.JSX.Element => {
  const { caseStore, userStore, metaDataStore } = useRootStore();
  const { t } = useTranslation();
  const currentUserId = userStore.loggedInUser.data.id;
  const defaultSurgeon = userStore.loggedInUser.isAttending
    ? metaDataStore.surgeonsOptions.find(option => option.value === currentUserId)
    : undefined;

  const defaultService = metaDataStore.servicesOptions[0];
  // tz: the default date should be the same as the selected date filter, no need to fix tz.
  const defaultDate = new Date(caseStore.selectedDateFilter);
  defaultDate.setHours(24, 0, 0, 0);
  const dateLib = new MomentAdapter();
  const fixedDate = dateLib.moment(defaultDate).subtract(1, 'days');
  const { setOurSearchParams } = useOurSearchParams();
  const defaultValues = {
    [FORM_FIELDS_ENUM.SURGEON]: defaultSurgeon,
    [FORM_FIELDS_ENUM.DATE]: fixedDate.toDate(),
    [FORM_FIELDS_ENUM.FOLLOWERS]: [],
    [FORM_FIELDS_ENUM.SERVICE]: defaultService
  };
  const { control, handleSubmit, formState, watch } = useForm<IFormData>({
    defaultValues
  });
  const { servicesOptions } = metaDataStore;
  const allValues = watch();
  const siteId = allValues[FORM_FIELDS_ENUM.SERVICE]?.value;
  const surgeonsOptions = siteId
    ? metaDataStore.getSurgeonsOptionsBySite(siteId)
    : metaDataStore.surgeonsOptions;

  const onSubmit = async (data: IFormData): Promise<void> => {
    try {
      if (data[FORM_FIELDS_ENUM.SERVICE] === undefined) {
        throw new Error('Service is required');
      }
      const newCase = await caseStore.createCase(data, metaDataStore.curOntVersion);

      caseStore.addCase(newCase);
      const followAllUsers = metaDataStore.followAllUsers(siteId as string);
      if (followAllUsers.length) {
        await newCase.setFollowersToServer(
          followAllUsers.map(follower => ({
            caseId: newCase.id,
            userId: follower.id
          }))
        );
      }

      AmplService.sendEvent(AmplService.EVENTS.ADD_A_CASE_CREATE);

      if (isCurrentUserToHandleCase(newCase.data, userStore.loggedInUser)) {
        caseStore.setIsNewCase(false);
        setOurSearchParams({ caseId: newCase.data.id, tab: TabsEnum.CASE }, true);
        return;
      }

      toast.success('Case created successfully');
      caseStore.setIsHuddleDialogOpen(false);
    } catch (error) {
      toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
      ErrorMonitor.captureException(error as Error);
      log(error);
    }
  };
  const handleClose = async (e: MouseEvent): Promise<void> => {
    caseStore.setIsHuddleDialogOpen(false);
    AmplService.sendEvent(AmplService.EVENTS.ADD_A_CASE_X);
  };

  const showServiceSelect = metaDataStore.servicesOptions.length > 1;
  const willNotify = allValues.surgeon
    ? getWillNotifyNewCase(
        allValues.surgeon.userId,
        allValues.surgeon.label,
        userStore.loggedInUser.data.id,
        allValues.date,
        metaDataStore.tz
      )
    : undefined;
  return (
    <div className="new-case-container">
      <form
        onSubmit={e => {
          e.preventDefault();
          AmplService.sendEvent(AmplService.EVENTS.ADD_A_CASE_CREATE);
        }}
      >
        <div className="form-header">
          <CloseButton handleClose={handleClose} />
          <SubmitButton
            id="new-case-form-submit-button"
            title="create"
            submitFunction={handleSubmit(onSubmit)}
            isDisabled={!formState.isValid}
          />
        </div>
        <div className="selects">
          <div className="selects-row">
            <SurgeonSelect
              control={control}
              options={surgeonsOptions}
              allValues={allValues}
              isReadOnly={lite}
            />
            <CaseDate control={control} allValues={allValues} disablePast />
          </div>
          <div className="selects-row">
            {showServiceSelect && <ServiceSelect control={control} options={servicesOptions} />}
          </div>
        </div>
        <CaseTitle control={control} isReadOnly={false} />
        {willNotify?.notify && (
          <div className="notifications">
            {willNotify.isNow ? (
              <CommentIcon stroke={variables.grey6} />
            ) : (
              <ClockIcon stroke={variables.grey6} />
            )}
            <div className="notification-text">{t(willNotify.msgKey, willNotify?.msgOptions)}</div>
          </div>
        )}
      </form>
    </div>
  );
};

export default observer(NewCaseForm);
