import { gql } from '@apollo/client';
import { CASE_STATE } from 'app/consts';
import { CASE_BASIC_DATA, CASE_FULL_DATA } from './fragments/case';
import { COMMENT_FULL_DATA } from 'app/services/apiService/api/fragments/comment';

const followCase = gql`
  mutation CareTeamFollowCase($case_id: uuid!, $user_id: uuid!) {
    insert_case_followers_one(
      object: { case_id: $case_id, user_id: $user_id }
      on_conflict: { constraint: case_followers_pkey, update_columns: [] }
    ) {
      case_id
    }
  }
`;

const updateLastSeen = gql`
  mutation CareTeamUpdateLastSeen($case_id: uuid!, $user_id: uuid!, $last_seen: timestamptz!) {
    update_case_followers(
      where: { case_id: { _eq: $case_id }, user_id: { _eq: $user_id } }
      _set: { last_seen: $last_seen }
    ) {
      affected_rows
    }
  }
`;

const unfollowCase = gql`
  mutation CareTeamUnfollowCase($case_id: uuid!, $user_id: uuid!) {
    delete_case_followers(where: { case_id: { _eq: $case_id }, user_id: { _eq: $user_id } }) {
      affected_rows
    }
  }
`;

const setCaseFollowers = gql`
  mutation CareTeamSetCaseFollowers(
    $case_id: uuid!
    $followers_ids: [uuid]!
    $followers: [case_followers_insert_input!]!
  ) {
    delete_case_followers(
      where: { case_id: { _eq: $case_id }, user_id: { _nin: $followers_ids } }
    ) {
      affected_rows
    }
    insert_case_followers(
      objects: $followers
      on_conflict: { constraint: case_followers_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`;

const careTeamGetAllDefaults = gql`
  query CareTeamGetAllDefaults($user_role_in_case: user_role_in_case_enum!) {
    care_team_defaults(where: { user_role_in_case: { _eq: $user_role_in_case } }) {
      user_id
      values
      procedure_id
      form
      stop_insights
    }
  }
`;

const careTeamGetDefaults = gql`
  query CareTeamGetDefaults(
    $pid: String!
    $form: form_enum!
    $user_role_in_case: user_role_in_case_enum!
    $user_id: uuid!
  ) {
    care_team_defaults(
      where: {
        procedure_id: { _eq: $pid }
        form: { _eq: $form }
        user_role_in_case: { _eq: $user_role_in_case }
        user_id: { _eq: $user_id }
      }
    ) {
      values
      never_save_defaults
    }
  }
`;

const getCase = gql`
  query CareTeamGetCase($id: uuid!) {
    cases_by_pk(id: $id) {
      ...CaseFullData
    }
    case_field_values_aggregate(where: { case_id: { _eq: $id } }) {
      nodes {
        user {
          id
        }
      }
    }
  }
  ${CASE_FULL_DATA}
`;

const getCaseValues = gql`
  query CareTeamGetCaseValues($id: uuid!) {
    case_field_values(where: { case_id: { _eq: $id } }) {
      field_id
      values
      other_values
    }
  }
`;

const getOnt = gql`
  query Ont($url: String!, $version: String!) {
    ont(url: $url, version: $version) {
      signedUrl
      version
    }
  }
`;

const getAll = gql`
  query CareTeamGetCases($date_gte: timestamptz!) {
    cases(
      order_by: { case_date: asc, index_in_day: asc }
      where: { state: { _neq: "new" }, case_date: { _gte: $date_gte } }
    ) {
      ...CaseBasicData
    }
  }
  ${CASE_BASIC_DATA}
`;

const getLiteCases = gql`
  query CareTeamGetLiteCases {
    cases(order_by: { index_in_day: asc }, where: { status: { _in: ["current", "upcoming"] } }) {
      ...CaseBasicData
    }
  }
  ${CASE_BASIC_DATA}
`;

const getMyCases = gql`
  query CareTeamGetMyCases($user_id: uuid!) {
    cases(
      order_by: { case_date: asc }
      where: { state: { _neq: "new" }, attending_id: { _eq: $user_id } }
    ) {
      ...CaseFullData
    }
  }
  ${CASE_FULL_DATA}
`;

/* eslint-disable prettier/prettier */
const getCasesByDescriptionOrDisplayId = gql`
  query CareTeamGetCasesByDescriptionOrDisplayId(
    $description: String!
    $display_id: String!
    $site_id: uuid!
  ) {
    cases_aggregate(
      distinct_on: procedure_id
      where: {
        _and: [
          { procedure_id: { _is_null: false } }
          { site_id: { _eq: $site_id } }
          {
            _or: [
              { display_id: { _ilike: $display_id } }
              { description: { _ilike: $description } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
      nodes {
        procedure_id
        procedure_title
      }
    }
  }
`;

const getOrCases = gql`
    query CareTeamGetOrCases(
        $date_gte: timestamptz!
        $date_lte: timestamptz!
    ) {
        cases(
            order_by: { case_date: asc }
            where: {
                _or: [
                    { _and: [
                        { case_date: {_gte: $date_gte}},
                        { case_date: {_lte: $date_lte}},
                        { state: { _nin: ["${CASE_STATE.ARCHIVED}", "${CASE_STATE.NEW}" ] } }
                    ] },
                    { state: { _in: ["${CASE_STATE.IN_BRIEF}","${CASE_STATE.BRIEF_IN_REVIEW}","${CASE_STATE.READY}" ] } },
                ]
            }
        ) {
            ...CaseFullData
        }
    }
    ${CASE_FULL_DATA}
`;

const updatePmmValue = gql`
  mutation CareTeamUpdatePmmValue(
    $pmm_value: case_field_values_insert_input!
    $case_id: uuid!
    $field_id: String!
  ) {
    delete_case_field_values(where: { case_id: { _eq: $case_id }, field_id: { _eq: $field_id } }) {
      affected_rows
    }
    insert_case_field_values(objects: [$pmm_value]) {
      affected_rows
    }
  }
`;

const deleteCaseStateLogs = gql`
  mutation DeleteCaseStateLogs(
    $case_id: uuid!
    $user_id: uuid!
    $from_state: case_state_enum!
    $to_state: case_state_enum!
  ) {
    delete_case_state_logs(
      where: {
        case_id: { _eq: $case_id }
        created_by_id: { _eq: $user_id }
        from_state: { _eq: $from_state }
        to_state: { _eq: $to_state }
      }
    ) {
      affected_rows
    }
  }
`;

const changeCaseStatus = gql`
  mutation CareTeamCaseStatus($case_id: uuid!, $days: Int!) {
    caseStatus(caseId: $case_id, days: $days) {
      success
    }
  }
`;

const submitCareTeamDefaults = gql`
  mutation CareTeamSubmitDefaults(
    $user_id: uuid!
    $form: form_enum!
    $procedure_id: String!
    $user_role_in_case: user_role_in_case_enum!
    $values: jsonb!
    $never_save_defaults: Boolean!
  ) {
    insert_care_team_defaults_one(
      object: {
        user_id: $user_id
        form: $form
        procedure_id: $procedure_id
        user_role_in_case: $user_role_in_case
        values: $values
        never_save_defaults: $never_save_defaults
      }
      on_conflict: {
        constraint: care_team_defaults_form_user_role_in_case_procedure_id_user_id_
        update_columns: [values, never_save_defaults]
      }
    ) {
      id
    }
  }
`;

const submitCareTeamDefaultsStopInsights = gql`
  mutation CareTeamUpdateDefaultsStopInsights(
    $user_id: uuid!
    $form: form_enum!
    $procedure_id: String!
    $user_role_in_case: user_role_in_case_enum!
    $stop_insights: jsonb!
  ) {
    update_care_team_defaults(
      where: {
        user_id: { _eq: $user_id }
        form: { _eq: $form }
        procedure_id: { _eq: $procedure_id }
        user_role_in_case: { _eq: $user_role_in_case }
      }
      _set: { stop_insights: $stop_insights }
    ) {
      affected_rows
    }
  }
`;

const submitBriefForm = gql`
  mutation CareTeamSubmitBriefForm(
    $case_id: uuid!
    $pmm_values: [case_field_values_insert_input!]!
  ) {
    delete_case_field_values(where: { case_id: { _eq: $case_id } }) {
      affected_rows
    }
    insert_case_field_values(objects: $pmm_values) {
      affected_rows
    }
  }
`;

const submitDebriefForm = gql`
  mutation CareTeamSubmitDebriefForm($case_id: uuid!, $case_values: cases_set_input!) {
    update_cases_by_pk(pk_columns: { id: $case_id }, _set: $case_values) {
      ...CaseFullData
    }
  }
  ${CASE_FULL_DATA}
`;

const create = gql`
  mutation CareTeamCreateCase($object: cases_insert_input!) {
    insert_cases_one(object: $object) {
      ...CaseFullData
    }
  }
  ${CASE_FULL_DATA}
`;

const updateCasesState = gql`
  mutation CareTeamUpdateCasesState($state: case_state_enum!, $ids: [uuid!]!) {
    update_cases(where: { id: { _in: $ids } }, _set: { state: $state }) {
      affected_rows
    }
  }
`;

const updateCaseValues = gql`
  mutation CareTeamUpdateCaseValues($values: cases_set_input!, $case_id: uuid!) {
    update_cases_by_pk(pk_columns: { id: $case_id }, _set: $values) {
      ...CaseFullData
    }
  }
  ${CASE_FULL_DATA}
`;

const clearCase = gql`
  mutation CareTeamClearCase($id: uuid!) {
    delete_case_field_values(where: { case_id: { _eq: $id } }) {
      affected_rows
    }
    delete_case_state_logs(where: { case_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const getAttendingNearCases = gql`
  query GetAttendingNearCases($user_id: uuid!, $date_lte: timestamptz!, $date_gte: timestamptz!) {
    cases(
      where: { attending_id: { _eq: $user_id }, case_date: { _gte: $date_gte, _lte: $date_lte } }
    ) {
      procedure_title
      case_date
    }
  }
`;

const getAttendingNearCasesExcludeId = gql`
  query GetAttendingNearCasesExcludeId(
    $user_id: uuid!
    $date_lte: timestamptz!
    $date_gte: timestamptz!
    $case_id: uuid!
  ) {
    cases(
      where: {
        attending_id: { _eq: $user_id }
        case_date: { _gte: $date_gte, _lte: $date_lte }
        id: { _neq: $case_id }
      }
    ) {
      procedure_title
      case_date
    }
  }
`;

const getCasesByProcedureId = gql`
  query GetCasesByProcedureId {
    cases_by_procedure_id {
      user_id
      procedure_id
      site_id
      count
    }
  }
`;

const getCasesSubscription = gql`
  subscription CareTeamGetCasesSubscription($date_gte: timestamptz!) {
    cases(
      order_by: { case_date: asc }
      where: { case_date: { _gte: $date_gte }, state: { _neq: "new" } }
    ) {
      ...CaseBasicData
    }
  }
  ${CASE_BASIC_DATA}
`;

const getLiteNonSurgeonCasesSubscription = gql`
  subscription CareTeamGetLiteNonSurgeonCasesSubscription {
    cases(order_by: { index_in_day: asc }, where: { status: { _in: ["current", "upcoming"] } }) {
      ...CaseBasicData
    }
  }
  ${CASE_BASIC_DATA}
`;

const getLiteSurgeonCasesSubscription = gql`
  subscription CareTeamGetLiteSurgeonCasesSubscription($user_id: uuid!) {
    cases(where: { state: { _neq: "new" }, _and: { attending_id: { _eq: $user_id } } }) {
      ...CaseBasicData
    }
  }
  ${CASE_BASIC_DATA}
`;

const getCaseCommentsSubscription = gql`
  subscription CareTeamGetCaseCommentsSubscription($case_id: uuid!) {
    comments(where: { case_id: { _eq: $case_id } }, order_by: { created_at: asc }) {
      ...CommentFullData
    }
  }
  ${COMMENT_FULL_DATA}
`;

const getCasesUnreadCommentsCountSubscription = gql`
    subscription CareTeamGetCasesUnreadCommentsCountSubscription($user_id: uuid!) {
        cases(
            where: {
                state: { _neq: "${CASE_STATE.ARCHIVED}" }
                comments: {
                    created_by_id: { _neq: $user_id }
                    _not: { meta_data: { created_by_id: { _eq: $user_id } } }
                }
            }
        ) {
            id
            comments_aggregate(
                where: {
                    created_by_id: { _neq: $user_id }
                    _not: { meta_data: { created_by_id: { _eq: $user_id } } }
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const getAttReadyCasesForTemplateRecommendations = gql`
    query CareTeamGetAttReadyCasesForTemplateRecommendations($user_id: uuid!, $procedure_id: String!, $limit: Int!) {
        cases(
            order_by: { case_date: desc }
            where: {
                procedure_id: {_eq: $procedure_id}
                case_state_logs: {to_state: {_eq: "${CASE_STATE.READY}"} }
                attending_id: { _eq: $user_id }
            }
            limit: $limit
        ) {
            ...CaseFullData
        }
    }
    ${CASE_FULL_DATA}
`;

const getFieldValuesCount = gql`
  query CareTeamGetFieldValuesCount($procedure_id: String, $user_id: uuid!, $site_id: uuid!) {
    field_values_count(
      where: { procedure_id: { _eq: $procedure_id }, site_id: { _eq: $site_id } }
    ) {
      site_id
      procedure_id
      field_id
      value
      count
      edits {
        new_value
        deleted
      }
    }
    get_field_values_count_per_user(
      args: { input_procedure_id: $procedure_id, user_id: $user_id, input_site_id: $site_id }
    ) {
      site_id
      procedure_id
      field_id
      value
      count
      edits {
        new_value
        deleted
      }
    }
  }
`;

const queries = {
  getAll,
  getLiteCases,
  getCase,
  getMyCases,
  getOrCases,
  getCasesByDescriptionOrDisplayId,
  getOnt,
  careTeamGetDefaults,
  careTeamGetAllDefaults,
  getCaseValues,
  getAttendingNearCases,
  getAttendingNearCasesExcludeId,
  getCasesByProcedureId,
  getAttReadyCasesForTemplateRecommendations,
  getFieldValuesCount
};

const mutations = {
  create,
  submitBriefForm,
  updateCasesState,
  submitDebriefForm,
  updateCaseValues,
  submitCareTeamDefaults,
  submitCareTeamDefaultsStopInsights,
  followCase,
  updateLastSeen,
  unfollowCase,
  setCaseFollowers,
  clearCase,
  updatePmmValue,
  deleteCaseStateLogs,
  changeCaseStatus
};
const subscriptions = {
  getCasesSubscription,
  getLiteNonSurgeonCasesSubscription,
  getLiteSurgeonCasesSubscription,
  getCaseCommentsSubscription,
  getCasesUnreadCommentsCountSubscription
};
const api = {
  queries,
  mutations,
  subscriptions
};

export default api;
