import type {
  IFullUserData,
  IMeta,
  IMetaDataStore,
  IProcedureDataWithCount,
  IRootStore,
  ISelectOption,
  ISelectOptionWithCount,
  ISiteData,
  IUserDataWithRoles,
  IUserSelectOption
} from './types';
import { flow, makeAutoObservable } from 'mobx';
import apiService from 'app/services/apiService';
import {
  getUploadcareKey,
  IS_SAML,
  isTrue,
  loadAndProcessProcedures,
  loadMetas,
  setOntVersionCache,
  SPECIALTIES
} from './metaDataHelper';
import { CARE_TEAM_ROLES, ROLES } from 'app/consts';
import { log } from 'debug';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';

const parseTextToArray = (text: string): string[] =>
  text !== '' ? JSON.parse(text.replace('{', '[').replace('}', ']')) : [];

class MetaDataStore implements IMetaDataStore {
  rootStore: IRootStore;
  sites: ISiteData[];
  users: IFullUserData[];
  curOntVersion = '';
  isSaml = false;
  loaded = false;
  loading = false;
  metaLoaded = false;
  procedures: IProcedureDataWithCount[];
  tz = '';
  uploadcareKey = '';
  pushOptIn: boolean = false;
  userSitesIds: string[] = [];

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false
    });
    this.rootStore = rootStore;
    this.sites = [];
    this.users = [];
    this.procedures = [];
  }

  get activeUsers(): IFullUserData[] {
    return this.users.filter(u => u.isActive);
  }

  setEnum = flow(function* (this: MetaDataStore, sitesIds: string, roles: string[]) {
    try {
      this.userSitesIds = parseTextToArray(sitesIds);
      const meta: IMeta[] = yield loadMetas(this.curOntVersion);
      const isSaml = meta.find(m => m.key === IS_SAML);
      const specialties = meta.find(m => m.key === SPECIALTIES)?.value;
      const isCareTeam = roles.includes(ROLES.CARE_TEAM);

      if (specialties === undefined) {
        throw new Error('Specialties are not defined');
      }

      if (isSaml !== undefined && isTrue(isSaml)) {
        this.setIsSaml(true);
      }
      const procedures = yield loadAndProcessProcedures(
        this.curOntVersion,
        isCareTeam,
        specialties
      );
      this.setProcedures(procedures);

      if (isCareTeam) {
        const uploadcareKey = yield getUploadcareKey(this.curOntVersion);

        this.setUploadcareKey(uploadcareKey);
      }
      // After updating everything, update the ont cached version
      setOntVersionCache(this.curOntVersion);

      const sites: ISiteData[] = yield apiService.getAllowedSites(this.userSitesIds);
      this.setSites(sites);
      apiService.setSites(sites);

      const tz = sites[0].timezone;
      if (sites.some(s => s.timezone !== tz)) {
        console.log('Sites', sites);
        throw new Error('Not all sites are in the same timezone');
      }
      this.initTz(tz);

      yield this.loadUsersList();
      this.setMetaLoaded(true);
    } catch (err) {
      log(err);
      throw err;
    }
  });

  initTz(tz: string): void {
    this.setTz(tz);
    this.rootStore.caseStore.setSelectedDateFilter(toZonedTime(new Date(), this.tz));
    apiService.setTimeZone(tz);
    apiService.setNeedTimezoneOverride(!this.isSameTimezone);
  }

  loadUsersList = flow(function* (this: MetaDataStore, logError: boolean = true) {
    const users: IFullUserData[] = yield apiService.getAllowedUsers(this.userSitesIds, logError);
    this.setUsers(users);
    apiService.setUsers(users);
  });

  setSites(sites: ISiteData[]): void {
    this.sites = sites;
  }

  setUsers(users: IFullUserData[]): void {
    this.users = users;
  }

  setCurOntVersion(curOntVersion: string): void {
    this.curOntVersion = curOntVersion;
  }

  setIsSaml(isSaml: boolean): void {
    this.isSaml = isSaml;
  }

  setLoaded(loaded: boolean): void {
    this.loaded = loaded;
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  setMetaLoaded(metaLoaded: boolean): void {
    this.metaLoaded = metaLoaded;
  }

  setProcedures(procedures: IProcedureDataWithCount[]): void {
    this.procedures = procedures;
  }

  setTz(tz: string): void {
    this.tz = tz;
  }

  setUploadcareKey(uploadcareKey: string): void {
    this.uploadcareKey = uploadcareKey;
  }

  reset(): void {
    this.setSites([]);
    this.setUsers([]);
    this.setCurOntVersion('');
    this.setIsSaml(false);
    this.setLoaded(false);
    this.setLoading(false);
    this.setMetaLoaded(false);
    this.setProcedures([]);
    this.setTz('');
    this.setUploadcareKey('');
  }

  get options(): {
    attendings: IFullUserData[];
    procedures: IProcedureDataWithCount[];
    sites: ISiteData[];
    careTeam: IFullUserData[];
  } {
    return {
      sites: this.sites,
      procedures: this.procedures,
      attendings: this.activeUsers.filter(u => u.roles.map(r => r.role).includes(ROLES.ATTENDING)),
      careTeam: this.activeUsers.filter(u =>
        u.roles.map(r => r.role).some(role => CARE_TEAM_ROLES.includes(role))
      )
    };
  }

  get surgeonsOptions(): IUserSelectOption[] {
    return this.options.attendings.map(parseUserToSelectOption);
  }

  get careTeamOptions(): IUserSelectOption[] {
    return this.options.careTeam.map(parseUserToSelectOption);
  }

  get servicesOptions(): ISelectOption[] {
    return this.options.sites.map(s => ({
      label: s.name,
      value: s.id
    }));
  }

  proceduresOptions = (siteId: string | undefined): ISelectOptionWithCount[] => {
    const site = this.sites.find(s => s.id === siteId);
    if (!site) {
      return [];
    }
    const options = [
      ...this.options.procedures
        .filter(p => site.specialties.includes(p.specialtyId))
        .map(p => ({
          label: p.name,
          value: p.id,
          countPerSiteAndAtt: p.countPerSiteAndAtt
        }))
    ];
    options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    return options;
  };

  getSurgeonsOptionsBySite = (siteId: string): IUserSelectOption[] => {
    return this.options.attendings
      .filter(u => u.userSites.find(site => site.siteId === siteId))
      .map(parseUserToSelectOption);
  };

  getCareTeamOptionsBySite = (siteId: string): IUserSelectOption[] => {
    return this.options.careTeam
      .filter(u => u.userSites.find(site => site.siteId === siteId))
      .map(parseUserToSelectOption);
  };

  setPushOptIn(pushOptIn: boolean): void {
    this.pushOptIn = pushOptIn;
    console.log('pushOptIn', pushOptIn);
  }

  followAllUsers(siteId: string): IFullUserData[] {
    return this.users.filter(u => u.followAll && u.userSites.find(s => s.siteId === siteId));
  }

  get isSameTimezone(): boolean {
    const localTimeZones = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.tz === localTimeZones;
  }

  formatDateInSiteTZ(date: Date, format: string): string {
    return formatInTimeZone(date, this.tz, format);
  }

  updateCurrentUserRole(oldRole: ROLES, newRole: ROLES): void {
    const user = this.users.find(u => u.id === this.rootStore.userStore.loggedInUser.data.id);
    if (!user) {
      throw new Error('Current user not found in users');
    }
    const newRoles = user.roles.map(role => {
      if (role.role === oldRole) {
        return { role: newRole };
      }
      return role;
    });
    user.roles = newRoles;
  }

  getVendorUsers = (siteId: string, vendor: string): IFullUserData[] => {
    return this.users.filter(
      u =>
        u.vendor?.split(',').find(val => val.toLowerCase() === vendor.toLowerCase()) &&
        u.roles.find(r => r.role === ROLES.VENDOR_REP) &&
        u.userSites.find(s => s.siteId === siteId)
    );
  };
}

export const parseUserToSelectOption = (u: IUserDataWithRoles): IUserSelectOption => {
  if (!u) {
    return {
      value: '',
      label: '',
      avatar: '',
      firstName: '',
      lastName: '',
      userId: '',
      roles: []
    };
  }

  return {
    value: u.id,
    label: u.nickName,
    avatar: u.avatar,
    firstName: u.firstName,
    lastName: u.lastName,
    userId: u.id,
    roles: u.roles
  };
};

export default MetaDataStore;
