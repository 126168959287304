import React, { useEffect, useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import type { IDynamicFieldValues, ISelectOption } from 'app/mobxStore/types';
import {
  FORM_FIELDS_ENUM,
  type IFormData,
  SERVER_FORM_FIELDS_ENUM
} from 'app/components/fields/types';
import type { Control, FormState } from 'react-hook-form/dist/types/form';
import { USER_CASE_ROLES } from '../../consts';
import CaseDescription from '../fields/CaseDescription';
import './FillCaseMetaForm.scss';
import ProcedureSelectWithConfirm from '../fields/ProcedureSelectWithConfirm';
import HuddleTemplate from '../huddleTemplate/HuddleTemplate';
import FormHeader from './FormHeader';
import { useDebounce } from '../../../@fuse/hooks';
import _ from 'lodash';

const DEBOUNCE_MSEC = 500;

interface IProps {
  showSubmit: boolean;
  control: Control<IFormData>;
  formState: FormState<IFormData>;
  allValues: IFormData;
  handleClose: () => Promise<void>;
  handleMetaDataChange: (field: SERVER_FORM_FIELDS_ENUM, value: string | Date) => Promise<void>;
  handleProcedureChange: (procedure: ISelectOption, approved: boolean) => Promise<boolean>;
  loadTemplate?: () => Promise<void>;
  briefFormAllValues?: IDynamicFieldValues;
  briefFormReset?: (values: IDynamicFieldValues) => void;
}

const FillCaseMetaForm = (props: IProps): React.JSX.Element => {
  const {
    handleProcedureChange,
    handleMetaDataChange,
    handleClose,
    formState,
    allValues,
    control,
    loadTemplate,
    briefFormAllValues,
    briefFormReset
  } = props;
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { caseStore, userRoleInCase, canUpdate, careTeamDefaultsStore } = useRootStore();
  const openedCase = caseStore.openedCase;
  if (openedCase === null) {
    return <></>;
  }

  const [curSelectedFollowers, setCurSelectedFollowers] = useState<ISelectOption[]>([]);
  const hasSavedPreference =
    openedCase.basicCase.data.procedureId !== null &&
    careTeamDefaultsStore.hasDefault(
      openedCase.basicCase.data.attendingId,
      openedCase.basicCase.data.procedureId
    );

  const showProcedureSelect = canUpdate;
  const showDescription =
    allValues[FORM_FIELDS_ENUM.PROCEDURE] !== undefined &&
    !openedCase.basicCase.data.standardProcedure &&
    (canUpdate || allValues[FORM_FIELDS_ENUM.DESCRIPTION]);
  const showHuddleTemplate = showDescription && userRoleInCase !== USER_CASE_ROLES.NONE;

  if (showHuddleTemplate && !loadTemplate) {
    throw new Error('loadTemplate is required when showHuddleTemplate is true');
  }

  const isFieldDirty = (field: FORM_FIELDS_ENUM): boolean => {
    return formState.dirtyFields[field] !== undefined;
  };

  const debounceUpdate = useDebounce(
    async (field: SERVER_FORM_FIELDS_ENUM, value: string | Date) => {
      await handleMetaDataChange(field, value);
    },
    DEBOUNCE_MSEC,
    {
      leading: false,
      trailing: true
    }
  );
  const selectedFollowers = allValues[FORM_FIELDS_ENUM.FOLLOWERS];
  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setCurSelectedFollowers(selectedFollowers);
      return;
    }
    if (_.isEqual(selectedFollowers, curSelectedFollowers)) {
      return;
    }
    setCurSelectedFollowers(selectedFollowers);
    void openedCase.basicCase.setFollowersToServer(
      selectedFollowers.map(follower => ({
        caseId: openedCase.basicCase.data.id,
        userId: follower.value
      }))
    );
    openedCase.basicCase.updateFollowersInStore(selectedFollowers.map(follower => follower.value));
  }, [selectedFollowers]);

  const onMetaChangeSelect = (
    formField: FORM_FIELDS_ENUM,
    serverField: SERVER_FORM_FIELDS_ENUM,
    selectedOption: ISelectOption | undefined
  ): void => {
    if (!isFieldDirty(formField)) {
      return;
    }
    if (selectedOption === undefined) {
      return;
    }
    void debounceUpdate(serverField, selectedOption.value);
  };
  const onMetaChangeValue = (
    formField: FORM_FIELDS_ENUM,
    serverField: SERVER_FORM_FIELDS_ENUM,
    value: string | Date
  ): void => {
    if (!isFieldDirty(formField)) {
      return;
    }
    void debounceUpdate(serverField, value);
  };

  const selectedService = allValues[FORM_FIELDS_ENUM.SERVICE];
  useEffect(() => {
    onMetaChangeSelect(FORM_FIELDS_ENUM.SERVICE, SERVER_FORM_FIELDS_ENUM.SERVICE, selectedService);
  }, [selectedService]);

  const selectedSurgeon = allValues[FORM_FIELDS_ENUM.SURGEON];
  useEffect(() => {
    onMetaChangeSelect(FORM_FIELDS_ENUM.SURGEON, SERVER_FORM_FIELDS_ENUM.SURGEON, selectedSurgeon);
  }, [selectedSurgeon]);

  const selectedDate = allValues[FORM_FIELDS_ENUM.DATE];
  useEffect(() => {
    onMetaChangeValue(FORM_FIELDS_ENUM.DATE, SERVER_FORM_FIELDS_ENUM.DATE, selectedDate);
  }, [selectedDate]);

  const title = allValues[FORM_FIELDS_ENUM.TITLE];
  useEffect(() => {
    onMetaChangeValue(FORM_FIELDS_ENUM.TITLE, SERVER_FORM_FIELDS_ENUM.TITLE, title);
  }, [title]);

  const description = allValues[FORM_FIELDS_ENUM.DESCRIPTION];
  useEffect(() => {
    onMetaChangeValue(
      FORM_FIELDS_ENUM.DESCRIPTION,
      SERVER_FORM_FIELDS_ENUM.DESCRIPTION,
      description
    );
  }, [description]);

  const room = allValues[FORM_FIELDS_ENUM.ROOM];
  useEffect(() => {
    onMetaChangeValue(FORM_FIELDS_ENUM.ROOM, SERVER_FORM_FIELDS_ENUM.ROOM, room);
    caseStore.openedCase?.basicCase.setRoomToStore(room);
  }, [room]);
  return (
    <form id="fill-case-form" className="fill-case-container">
      <FormHeader
        handleClose={handleClose}
        formState={formState}
        allValues={allValues}
        control={control}
        loadTemplate={loadTemplate}
      />
      {showProcedureSelect && (
        <ProcedureSelectWithConfirm
          control={control}
          allValues={allValues}
          handleProcedureChange={handleProcedureChange}
        />
      )}
      {showHuddleTemplate && (
        <HuddleTemplate
          hasSavedPreference={hasSavedPreference}
          loadTemplate={loadTemplate}
          briefFormAllValues={briefFormAllValues}
          briefFormReset={briefFormReset}
        />
      )}
      {showDescription && <CaseDescription control={control} isReadOnly={!canUpdate} />}
    </form>
  );
};

export default observer(FillCaseMetaForm);
