import React from 'react';
import { type ICase, TabsEnum } from 'app/mobxStore/types';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import './CaseListItem.scss';
import { useRootStore } from 'app/mobxStore';
import useFollow from 'utils/form/useFollow';
import { observer } from 'mobx-react-lite';
import {
  getUserRoleInCase,
  needsBriefApproval,
  needsBriefReview
} from '../../../../utils/form/shared';
import CaseListItemStandardProc from './CaseListItemStandardProc';
import useOurSearchParams from '../../caseViews/hooks/useOurSearchParams';
import AmplService from '../../../services/amplService/amplService';
import TooltipWrapper from 'app/components/tooltipWrapper/TooltipWrapper';
import { setClickedUnfollowTooltip } from 'app/mobxStore/storage';

interface IProps {
  kase: ICase;
}

const CaseListItemCta = (props: IProps): React.JSX.Element => {
  const { kase } = props;
  const { data: caseData } = kase;

  const { setOurSearchParams } = useOurSearchParams();
  const { userStore, caseStore } = useRootStore();
  const { toggleFollow, submitting, isFollowed } = useFollow({ case: props.kase });
  const userRoleInCase = getUserRoleInCase(caseData, userStore.loggedInUser.data);
  const showJoinButton = !userStore.loggedInUser.isAttending;

  const showHuddleButton =
    needsBriefApproval(caseData.isReady, kase.isInPast(), userRoleInCase) ||
    needsBriefReview(caseData.state, caseData.assistantFilledBrief, userRoleInCase);

  const huddleOnClick = (): void => {
    setOurSearchParams({ caseId: caseData.id, tab: TabsEnum.CASE }, true);
  };

  const handleCloseTooltip = (): void => {
    setClickedUnfollowTooltip(true);
    caseStore.setCaseToShowUnfollowTooltip(null);
  };

  const getFollowButton = (children: JSX.Element): JSX.Element => {
    const showTooltip =
      isFollowed &&
      caseStore.caseToShowUnfollowTooltip === caseData.id &&
      caseStore.isUserFollowedCaseInPast;

    if (!showTooltip) {
      return children;
    }

    return (
      <TooltipWrapper
        open
        name={'unfollow'}
        handleCloseTooltip={handleCloseTooltip}
        comp={children}
        placement="top-start"
        offset={[10, -4]}
        tooltipContent={
          <Trans
            i18nKey="unfollowTooltipText"
            components={{
              bold: <span style={{ fontWeight: 600 }} />
            }}
          />
        }
      />
    );
  };

  const followButton = getFollowButton(
    <div>
      <Button
        disabled={submitting}
        classNames={clsx('join-button case-list-item-button outlined', { full: !isFollowed })}
        buttonText={isFollowed ? 'leave' : 'join'}
        onClick={async e => {
          e.stopPropagation();
          void toggleFollow();
          const event = isFollowed
            ? AmplService.EVENTS.HOME_CASE_LIST_ITEM_UNFOLLOW
            : AmplService.EVENTS.HOME_CASE_LIST_ITEM_FOLLOW;
          AmplService.sendCaseEvent(event, props.kase);
        }}
      />
    </div>
  );

  return (
    <>
      {showJoinButton && followButton}
      {showHuddleButton && (
        <Button
          disabled={submitting} // @todo bug that it is looking at the useFollow submitting?
          classNames={clsx('huddle-button case-list-item-button outlined', { full: true })}
          buttonText={'huddleButton'}
          onClick={async e => {
            e.stopPropagation();
            huddleOnClick();
            AmplService.sendCaseEvent(AmplService.EVENTS.HOME_CASE_LIST_ITEM_HUDDLE, props.kase);
          }}
        />
      )}
      <CaseListItemStandardProc kase={props.kase} showHuddleButton={showHuddleButton} />
    </>
  );
};

export default observer(CaseListItemCta);
